import React, { useEffect } from "react";
import formFields from "./inputs";
import SubmitForm from "../forms/submitForm";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import { otherAPI, usersAPI } from "../../assets/apiFunctions";
import messageNotification, { SetNotificationClass } from "../../assets/messageNotification";

const ChangePasswordComponent = (props) => {
  const [, dispatch] = useMaterialUIController();
  const [resetPassword, setResetPassword] = React.useState({});
  const handleChange = (e) => {
    setResetPassword({ ...resetPassword, [e.target.name]: e.target.value });
  };
  const validateForm = async () => {
    setResetPassword({ ...resetPassword, error: {} });

    const passwordErrors = [];

    if (resetPassword.password.length < 8) {
      passwordErrors.push("Password must be at least 8 characters.");
    }

    if (resetPassword.password !== resetPassword.confirmPassword) {
      passwordErrors.push("Passwords don't match.");
    }
    if (passwordErrors.length > 0) {
      setResetPassword({
        ...resetPassword,
        error: { password: { message: passwordErrors } },
      });
    }

    return { hasErrors: passwordErrors.length > 0 };
  };
  const submit = async (e) => {
    e.preventDefault();
    const validationResult = await validateForm();
    if (!validationResult.hasErrors) {
      const data = new FormData();
      data.append("data", JSON.stringify(resetPassword));
      const notification = new SetNotificationClass();
      setLoader(dispatch, true);
      try {
        const res = await otherAPI.changePassword(data);
        if (res.data.status === true) {
          setNotification(dispatch, notification.SUCCESS_OPERATION());
          setResetPassword({});
        }
      } catch (error) {
        setNotification(dispatch, notification.FAILED_OPERATION());
      } finally {
        setLoader(dispatch, false);
      }
    }
  };
  return (
    <div>
      <SubmitForm
        inputs={formFields}
        handleChange={handleChange}
        submit={submit}
        user={resetPassword}
      />
    </div>
  );
};

export default ChangePasswordComponent;
