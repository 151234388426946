import React from "react";
import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import MDTypography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const MdRadio = ({ properties, value, handleChange, options, name, label, defaultValue }) => {
  return (
    <Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4} key={properties.name}>
      {label}
      <RadioGroup
        name={properties.name}
        sx={{ display: "flex", flexDirection: "row" }}
        aria-labelledby="demo-radio-buttons-group-label"
        onChange={handleChange}
        value={value && value[name] !== undefined ? value[name] : defaultValue}
      >
        {properties.options &&
          properties.options.map((o) => (
            <FormControlLabel key={o} value={o} control={<Radio />} label={o} />
          ))}
      </RadioGroup>
    </Box>
  );
};

MdRadio.propTypes = {
  value: PropTypes.any,
  handleChange: PropTypes.any,
  options: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  properties: PropTypes.any,
  label: PropTypes.any,
};

export default MdRadio;
