export default {};

export const ErrorCodes = {
  409: {
    open: true,
    message: "name is already exist",
    type: "error",
  },
};

export class SetNotificationClass {
  constructor(message) {
    this.message = message;
  }
  WARNING() {
    return {
      open: true,
      message: this.message || "Default",
      type: "warning",
    };
  }
  FILED_REQUIRED() {
    return {
      open: true,
      message: this.message || "All fields are mandatory and must not be left empty.",
      type: "error",
    };
  }
  FILED_REQUIRED() {
    return {
      open: true,
      message: "All fields are mandatory and must not be left empty.",
      type: "error",
    };
  }
  CAR_EXIST() {
    return {
      open: true,
      message: this.message || "Car already exist",
      type: "error",
    };
  }
  SUCCESS_OPERATION() {
    return { open: true, message: this.message || "The operation was successful", type: "success" };
  }
  FAILED_OPERATION() {
    return {
      open: true,
      message: this.message || "operation failed",
      type: "error",
    };
  }
  Exist_user() {
    return {
      open: true,
      message: this.message || "this email is already exist",
      type: "error",
    };
  }
  FAILED_CONFIRMATION() {
    return {
      open: true,
      message: this.message || "This code is invalid or expired",
      type: "error",
    };
  }
  SUCCESS_CONFIRMATION() {
    return {
      open: true,
      message: this.message || "Password has been successfully reset",
      type: "success",
    };
  }
}
